import React from 'react'
import { useSelector } from 'react-redux'
import { PiWhatsappLogoFill } from "react-icons/pi";
import { FaInstagram } from "react-icons/fa";

function Support() {
    const {settings}=useSelector(s=>s.userReducer)
    let url = `https://wa.me/+91${settings?.whatsapp_number}`
    let instaurl=`https://www.instagram.com/babaludoweb?utm_source=qr&igsh=MTJqOWhhbDJmY20yZA==`
  return (
    <div className='fixed bottom-7 right-5 bg-white rounded-full p- cursor-pointer bs z-10 flex flex-col gap-2' >
    <FaInstagram size={50} className=' text-red-600' onClick={()=>window.open(instaurl,"blank")}/> 
    <PiWhatsappLogoFill size={50} className=' text-green-600' onClick={()=>window.open(url,"blank")}/> 
 </div>
  )
}

export default Support